@tailwind base;
@tailwind components;
@tailwind utilities;


@import '../node_modules/react-grid-layout/css/styles.css';

/* @font-face {
  font-family: 'coffeetea_demoregular';
  src: url('/fonts/coffeetea/font_logo_coffeeteademo-regular-webfont.woff2') format('woff2'),
       url('/fonts/coffeetea/font_logo_coffeeteademo-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Amatic+SC&family=Architects+Daughter&family=Arvo&family=Cabin&family=Comfortaa&family=Comforter+Brush&family=Concert+One&family=Dancing+Script&family=Estonia&family=Exo+2:wght@200&family=Hubballi&family=Karla:wght@200&family=Lobster&family=Lora&family=Mali&family=Montserrat:wght@200&family=Orbitron&family=Permanent+Marker&family=Play&family=Poiret+One&family=Poppins&family=Rakkas&family=Red+Hat+Display&family=Righteous&family=Roboto:wght@300&family=Sacramento&family=Source+Sans+Pro:wght@200&family=Titillium+Web&family=Yatra+One&display=swap');

body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  min-height: 100%;
  margin: 0; padding: 0;
  bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* GENERIC */ 
.mart0 { margin-top: 0px; }
.mart2 { margin-top: 2%; }
.mart5 { margin-top: 5%; }

.p-6 { padding: 6%; }
.p-7 { padding: 7%; }
.p-8 { padding: 8%; }
.p-9 { padding: 9%; }
.p-10 { padding: 10%; }
.p-11 { padding: 11%; }
.p-12 { padding: 12%; }
.p-13 { padding: 13%; }
.p-14 { padding: 6%; }
.p-15 { padding: 15%; }

.m-6 { margin: 6%; }
.m-7 { margin: 7%; }
.m-8 { margin: 8%; }
.m-9 { margin: 9%; }
.m-10 { margin: 10%; }
.m-11 { margin: 11%; }
.m-12 { margin: 12%; }
.m-13 { margin: 13%; }
.m-14 { margin: 6%; }
.m-15 { margin: 15%; }


h1,h2,h3,h4,h5 { font-family: 'Montserrat'; }

/* HEADER */
.header-fixed-top { position: fixed; width: 100%; z-index: 10; }
.header-fixed-top a,.header-fixed-top button { border-radius: 0px; }
.header-top { width: 100%; z-index: 10; }
.header-fixed-bottom { position: fixed; bottom: 0; width: 100%; z-index: 10; }

.navbar-light .navbar-toggler-icon { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%125, 125, 125, 1.5%29' stroke-linecap='round' stroke-miterlimit='10' strokeWidth='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
.dropdown:hover .dropdown-menu { display: block; cursor: pointer; }
  /* FIX Toogle Button */
.company-logo { cursor: pointer; max-width: 300px; }
.slide-top .row { margin: 0; }
.slide-logo { max-width: 50%; margin-left: auto; margin-right: auto; }
.mt-100 { padding-top: 90px; }

.w-130 { width: 430px; }

/* NAVBAR Multi da https://codepen.io/ajaypatelaj/pen/prHjD */
.dropdown-submenu { position: relative; }
.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
/* End NAVBAR Multi */

/* FOOTER */
.footer { padding-top: 1%; }
.footer ul { margin: 0px; padding: 0px; list-style: none; }

/* GOOGLE MAP */ 
.simple-map { background-color: #121212; padding-bottom: 2%;}
.simple-map .map-logo { max-width: 40px; }

/* Survey */
.items-center p { margin: 0; padding: 0; }

/* Carousel */
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  height: 50vh;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
  color: #2b6cb0;  /*Set to match the Tailwind colour you want the active one to be */
}
