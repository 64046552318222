.home { min-height: 775px; }
.home .slide-top div { padding-top: 2%; }
@media(max-width:768px) {
    .home .slide-top div { padding-top: 0%; }
}
.home .description { width: 100%; height: 100%; }

.home .top-service { padding-bottom: 3%; padding-top: 2%; }
.home .top-service .top-service-text { color: #000; }
.home .top-service .top-service-text p { color: #000; }

.home .employee { padding-bottom: 3%; padding-top: 2%; }
.home .employee .employee-text { color: #000; }

/* Main col of page list */ 
.col-main { padding-right: 0px !important; padding-left: 0px !important;  }
.row { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; flex-wrap: wrap; }
.row > [class*='col-'] { display: flex; flex-direction: column; }