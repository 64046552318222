.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo { animation: App-logo-spin infinite 20s linear; }
}

.img-logo { max-height: 80px; }

.App-link {
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-box { overflow: hidden; }

.container-alert { position: fixed; bottom: 2%; }

.react-cookie-law-dialog { position: fixed; bottom:0px; left: 0px; right: 0px; padding: 10px; }
.react-cookie-law-container { border: 1px; border-radius: 1%;  background-color: #aaa; padding: 3%; }

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #DDDDDD;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}